<template>
  <div class="card p-3 shadow-sm">
    <form @submit.prevent="confirmUpdateVessel">
      <div class="row justify-content-between align-content-center mb-3">
        <div class="col-auto">
          <h5 class="e-text-red lead font-weight-bold">Vessel Details</h5>
        </div>
        <div class="col-auto">
          <template v-if="isAllowed('vessels.particular.edit')">
            <button class="btn btn-sm btn-xs btn-danger font-weight-bold e-text-white sm-button-font" type="button"
                    v-if="toEdit"
                    @click="cancelEditVessel">
              <font-awesome-icon icon="ban"/>
              Cancel
            </button>
            <!--          <button class="e-btn e-btn-green ml-2" type="button" v-else @click="editValues">Edit Vessel Details</button>-->
            <button class="btn btn-sm btn-warning font-weight-bold e-text-black sm-button-font" type="button" v-else
                    @click="editValues">
              <font-awesome-icon icon="pen"/>
              Edit Vessel Details
            </button>
            <button class="btn btn-sm btn-xs btn-primary font-weight-bold e-text-white ml-2 sm-button-font" type="submit"
                    v-if="toEdit">
              <font-awesome-icon icon="save"/>
              Save
            </button>
          </template>


          <button class="btn btn-sm btn-primary font-weight-bold e-text-white sm-button-font ml-2" type="button" @click="downloadAIReportTemplate">
            <font-awesome-icon icon="file-excel"/>
            Download Accident Incident Template
          </button>
          <button id="committeeReport" aria-expanded="false" aria-haspopup="true" class="ml-2 mr-2 e-btn e-btn-blue-gray force-white-all"
                  data-toggle="dropdown">
            <font-awesome-icon class="mr-1" icon="download"/>
            Download Ship's Particular
          </button>
          <div aria-labelledby="committeeReport" class="dropdown-menu">
            <button class="dropdown-item text-secondary font-weight-bolder" @click="downloadParticular('excel')" type="button">
              <font-awesome-icon icon="file-excel"/>
              Excel
            </button>
            <button class="dropdown-item text-secondary font-weight-bolder" @click.prevent="downloadParticular('pdf')" type="button">
              <font-awesome-icon icon="file-pdf"/>
              PDF
            </button>
          </div>

        </div>
      </div>

      <div class="row text-small mt-3">
        <div class="col-sm-5 mx-auto text-center">
          <div class="row text-small m-3">
            <div class="col col-md-12">
              <img
                v-if="editVesselDetails.vessel_image"
                class="border vessel-pic float-left"
                alt="Vessel Image"
                :src="editVesselDetails.vessel_image"
              />
              <img
                v-else
                class="border float-left image-vessel"
                alt="Vessel Image"
                :src="require(`@/assets/crew.png`)"
              />
              <!--              <img-->
              <!--                v-else-->
              <!--                class="border vessel-pic float-left"-->
              <!--                alt="Vessel Image"-->
              <!--                :src="require(`@/assets/crew.png`)"-->
              <!--              />-->
            </div>
            <div class="col col-md-12">
              <div v-if="toEdit">
                <h6 class="font-weight-bolder text-left mt-3"><font-awesome-icon icon="upload"/> Image Upload</h6>
                <input
                  type="file"
                  class="form-control form-control-sm"
                  name="vessel_image"
                  id="vessel_image"
                  accept="image/jpeg"
                />
              </div>
              <hr/>
            </div>

            <div class="col-md-4">
              <h6 class="font-weight-bolder text-left">Assigned Manning</h6>
              <div class="form-group row">
                <div
                  class="col-sm-12 offset-1 text-left"
                  v-for="manningKey in manningKeys" :key="manningKey.id"
                >
                  <div class="form-check d-block" v-if="toEdit">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      :value="manningKey"
                      :id="'manningKey' + manningKey.id"
                      v-model="editVesselDetails.mannings"
                    />
                    <label
                      class="form-check-label w-100 mt-1"
                      :for="'manningKey' + manningKey.id"
                    >
                      {{ manningKey.alias }}
                    </label>
                  </div>

                  <div class="form-check d-block" v-else>
                    <input
                      class="form-check-input"
                      type="checkbox"
                      :value="manningKey.id"
                      :id="'manningKeyView' + manningKey.id"
                      :checked="isManningChecked(manningKey.id)"
                      disabled
                    />
                    <label
                      class="form-check-label w-100 mt-1"
                      :for="'manningKeyView' + manningKey.id"
                    >
                      {{ manningKey.alias }}
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <!--            <div class="col-md-8">-->
            <!--              <h6 class="font-weight-bolder text-left">ECDIS Type</h6>-->
            <!--              <div class="form-group row">-->
            <!--                <div-->
            <!--                  class="col-sm-12 offset-1 text-left mb-2"-->
            <!--                >-->

            <!--                  <div class="d-bloc">-->
            <!--                    <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="check-square"-->
            <!--                         class="text-primary svg-inline&#45;&#45;fa fa-check-square fa-w-14" role="img"-->
            <!--                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">-->
            <!--                      <path fill="currentColor"-->
            <!--                            d="M400 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V80c0-26.51-21.49-48-48-48zm0 400H48V80h352v352zm-35.864-241.724L191.547 361.48c-4.705 4.667-12.303 4.637-16.97-.068l-90.781-91.516c-4.667-4.705-4.637-12.303.069-16.971l22.719-22.536c4.705-4.667 12.303-4.637 16.97.069l59.792 60.277 141.352-140.216c4.705-4.667 12.303-4.637 16.97.068l22.536 22.718c4.667 4.706 4.637 12.304-.068 16.971z"></path>-->
            <!--                    </svg>-->
            <!--                    &lt;!&ndash;                    <svg v-else aria-hidden="true" focusable="false" data-prefix="far" data-icon="square" class="text-primary svg-inline&#45;&#45;fa fa-square fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zm-6 400H54c-3.3 0-6-2.7-6-6V86c0-3.3 2.7-6 6-6h340c3.3 0 6 2.7 6 6v340c0 3.3-2.7 6-6 6z"></path></svg>&ndash;&gt;-->
            <!--                    <span class="ml-2">ecdis</span>-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--            </div>-->

          </div>
        </div>

        <div class="col-sm-7">
          <table class="table table-sm" id="genInfoTable">
            <tbody>
            <tr>
              <th scope="row" class="align-middle">
                <label
                  class="font-weight-bolder text-left form-label-xs"
                  for="vessel-name"
                >
                  Ship Name:
                </label>
              </th>
              <td class="align-middle text-uppercase">
                <input type="text" class="form-control form-control-sm" v-if="toEdit" v-model="editVesselDetails.name">
                <span v-else>{{ vessel.name }}</span>
                <!--                <span>ship name</span>-->
              </td>
            </tr>

            <tr>
              <th scope="row" class="align-middle">
                <label
                  class="font-weight-bolder text-left form-label-xs"
                  for="flag"
                >
                  Status:
                </label>
              </th>
              <td class="align-middle text-uppercase">
                <select class="form-control form-control-sm" v-if="toEdit" v-model="editVesselDetails.active">
                  <option value="1">ACTIVE</option>
                  <option value="0">INACTIVE</option>
                </select>
                <span v-else>{{ vessel.active === 1 ? 'ACTIVE' : 'INACTIVE' }}</span>
              </td>
            </tr>

            <tr>
              <th scope="row" class="align-middle">
                <label
                  class="font-weight-bolder text-left form-label-xs"
                  for="vessel-type"
                >
                  Abbreviation:
                </label>
              </th>
              <td class="align-middle text-uppercase">
                <input type="text" class="form-control form-control-sm" v-if="toEdit"
                       v-model="editVesselDetails.abbreviation">
                <span v-else>{{ vessel.abbreviation }}</span>
              </td>
            </tr>

            <tr>
              <th scope="row" class="align-middle">
                <label
                  class="font-weight-bolder text-left form-label-xs"
                  for="vessel-group"
                >
                  Previous Names:
                </label>
              </th>
              <td class="align-middle text-uppercase">
                <input type="text" class="form-control form-control-sm" v-if="toEdit"
                       v-model="editVesselDetails.previous_names">
                <span v-else>{{ vessel.previous_names }}</span>
              </td>
            </tr>

            <tr>
              <th scope="row" class="align-middle">
                <label
                  class="font-weight-bolder text-left form-label-xs"
                  for="port"
                >
                  Ship Type:
                </label>
              </th>
              <td class="align-middle text-uppercase">
                <select class="form-control form-control-sm" v-if="toEdit" v-model="editVesselDetails.vessel_type_id">
                  <option :value="vesselType.id"
                          :key="vesselType.id"
                          v-for="vesselType in vesselTypeKeys">
                    {{ vesselType.name ? vesselType.name.toUpperCase() : '' }}
                  </option>
                </select>
                <span v-else>{{ vessel.vessel_type_name }}</span>
              </td>
            </tr>

            <tr>
              <th scope="row" class="align-middle">
                <label
                  class="font-weight-bolder text-left form-label-xs"
                  for="port"
                >
                  IMO No.:
                </label>
              </th>
              <td class="align-middle text-uppercase">
<!--                <input type="text" class="form-control form-control-sm" v-if="toEdit"-->
<!--                       v-model="editVesselDetails.imo_no">-->
                <span>{{ vessel.imo_no }}</span>
              </td>
            </tr>

            <tr>
              <th scope="row" class="align-middle">
                <label
                  class="font-weight-bolder text-left form-label-xs"
                  for="imo"
                >
                  Call Sign:
                </label>
              </th>
              <td class="align-middle text-uppercase">
                <input type="text" class="form-control form-control-sm" v-if="toEdit"
                       v-model="editVesselDetails.call_sign">
                <span v-else>{{ vessel.call_sign }}</span>
              </td>
            </tr>

            <tr
            >
              <th scope="row" class="align-middle">
                <label
                  class="font-weight-bolder text-left form-label-xs"
                  for="imo"
                >
                  Flag:
                </label>
              </th>
              <td class="align-middle">
                <select class="form-control form-control-sm" v-if="toEdit" v-model="editVesselDetails.flag_id">
                  <option :value="country.id" :key="country.id" class="text-uppercase"
                          v-for="country in countryKeys">{{ country.name ? country.name.toUpperCase() : '' }}
                  </option>
                </select>
                <span v-else>{{ vessel.flag_name }}</span>
              </td>
            </tr>

            <tr>
              <th scope="row" class="align-middle text-uppercase">
                <label
                  class="font-weight-bolder text-left form-label-xs"
                  for="official"
                >
                  MMSI:
                </label>
              </th>
              <td class="align-middle text-uppercase">
                <input type="text" class="form-control form-control-sm" v-if="toEdit" v-model="editVesselDetails.mmsi">
                <span v-else>{{ vessel.mmsi }}</span>
              </td>
            </tr>

            <tr
            >
              <th scope="row" class="align-middle">
                <label
                  class="font-weight-bolder text-left form-label-xs"
                  for="classification"
                >
                  Class:
                </label>
              </th>
              <td class="align-middle text-uppercase">
                <input type="text" class="form-control form-control-sm" v-if="toEdit" v-model="editVesselDetails.class">
                <span v-else>{{ vessel.class }}</span>
              </td>
            </tr>

            <tr>
              <th scope="row" class="align-middle">
                <label
                  class="font-weight-bolder text-left form-label-xs"
                  for="hull_no"
                >
                  Class No.
                </label>
              </th>
              <td class="align-middle text-uppercase">
                <input type="text" class="form-control form-control-sm" v-if="toEdit"
                       v-model="editVesselDetails.class_no">
                <span v-else>{{ vessel.class_no }}</span>
              </td>
            </tr>

            <tr>
              <th scope="row" class="align-middle">
                <label
                  class="font-weight-bolder text-left form-label-xs"
                  for="cba_type"
                >
                  Classification Characters, <br> Notations:
                </label>
              </th>
              <td class="align-middle  text-uppercase">
                <input type="text" class="form-control form-control-sm" v-if="toEdit"
                       v-model="editVesselDetails.classification_characters">
                <span v-else>{{ vessel.classification_characters }}</span>
              </td>
            </tr>

            <tr>
              <th scope="row" class="align-middle">
                <label
                  class="font-weight-bolder text-left form-label-xs"
                  for="cba_type"
                >
                  Installation Characters:
                </label>
              </th>
              <td class="align-middle text-uppercase">
                <input type="text" class="form-control form-control-sm" v-if="toEdit"
                       v-model="editVesselDetails.installation_characters">
                <span v-else>{{ vessel.installation_characters }}</span>
              </td>
            </tr>

            <!--            <tr v-if="toEdit">-->
            <!--              <th scope="row" class="align-middle">-->
            <!--                <label-->
            <!--                  class="font-weight-bolder text-left form-label-xs"-->
            <!--                  for="vessel_image"-->
            <!--                >-->
            <!--                  Image Upload-->
            <!--                </label>-->
            <!--              </th>-->
            <!--              <td class="align-middle text-uppercase">-->
            <!--                <input-->
            <!--                  type="file"-->
            <!--                  class="form-control form-control-sm"-->
            <!--                  name="vessel_image"-->
            <!--                  id="vessel_image"-->
            <!--                  accept="image/jpeg"-->
            <!--                />-->
            <!--              </td>-->
            <!--            </tr>-->

            </tbody>
          </table>
        </div>
      </div>

      <div class="row text-small mt-3">
        <div class="col-6">
          <table class="table table-sm">
            <tbody>
            <tr>
              <td class="font-weight-bold"> Tonnage Gross(International)</td>
              <td class="w-50">
                <input type="text" class="form-control form-control-sm" v-if="toEdit"
                       v-model="editVesselDetails.tonnage_gross_international">
                <span v-else>{{ vessel.tonnage_gross_international }}</span>
              </td>
            </tr>
            <tr>
              <td class="font-weight-bold w-50">Tonnage Gross(Registered)</td>
              <td class="w-50">
                <input type="text" class="form-control form-control-sm" v-if="toEdit"
                       v-model="editVesselDetails.tonnage_gross_registered">
                <span v-else>{{ vessel.tonnage_gross_registered }}</span>
              </td>
            </tr>
            <tr>
              <td class="font-weight-bold w-50">Deadweight</td>
              <td class="w-50">
                <input type="text" class="form-control form-control-sm" v-if="toEdit"
                       v-model="editVesselDetails.dead_weight">
                <span v-else>{{ vessel.dead_weight }}</span>
              </td>
            </tr>
            <tr>
              <td class="font-weight-bold w-50">Summer Freeboard</td>
              <td class="w-50">
                <input type="text" class="form-control form-control-sm" v-if="toEdit"
                       v-model="editVesselDetails.summer_freeboard">
                <span v-else>{{ vessel.summer_freeboard }}</span>
              </td>
            </tr>
            <tr>
              <td class="font-weight-bold w-50">Summer Draft</td>
              <td class="w-50">
                <input type="text" class="form-control form-control-sm" v-if="toEdit"
                       v-model="editVesselDetails.summer_draft">
                <span v-else>{{ vessel.summer_draft }}</span>
              </td>
            </tr>
            <tr>
              <td class="font-weight-bold w-50">LOA (m)</td>
              <td class="w-50">
                <input type="text" class="form-control form-control-sm" v-if="toEdit" v-model="editVesselDetails.loa">
                <span v-else>{{ vessel.loa }}</span>
              </td>
            </tr>
            <tr>
              <td class="font-weight-bold w-50">Moulded LxBxD</td>
              <td class="w-50">
                <input type="text" class="form-control form-control-sm" v-if="toEdit"
                       v-model="editVesselDetails.moulded_lbd">
                <span v-else>{{ vessel.moulded_lbd }}</span>
              </td>
            </tr>
            <tr>
              <td class="font-weight-bold w-50">Registered LxBxD</td>
              <td class="w-50">
                <input type="text" class="form-control form-control-sm" v-if="toEdit"
                       v-model="editVesselDetails.registered_lbd">
                <span v-else>{{ vessel.registered_lbd }}</span>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="col-6">
          <table class="table table-sm">
            <tbody>
            <tr>
              <th class="font-weight-bold w-50">Shipbuilder</th>
              <td class="w-50">
                <input type="text" class="form-control form-control-sm" v-if="toEdit"
                       v-model="editVesselDetails.shipbuilder">
                <span v-else>{{ vessel.shipbuilder }}</span>
              </td>
            </tr>
            <tr>
              <th class="font-weight-bold w-50">Hull Number</th>
              <td class="w-50">
                <input type="text" class="form-control form-control-sm" v-if="toEdit"
                       v-model="editVesselDetails.hull_number">
                <span v-else>{{ vessel.hull_number }}</span>
              </td>
            </tr>
            <tr>
              <th class="font-weight-bold w-50">Sister ship of</th>
              <td class="w-50">
                <input type="text" class="form-control form-control-sm" v-if="toEdit"
                       v-model="editVesselDetails.sister_ship_of">
                <template v-else>
                  <button type="button"  class="btn btn-secondary btn-xs mr-2" style="border-radius: 0.3rem !important;"  data-target="#sisterShipList" data-toggle="modal">
                    <span style="padding: 0.2em">
                       <font-awesome-icon icon="list" class="force-white-all"></font-awesome-icon>
                    </span>
                  </button>
                  {{ vessel.sister_ship_of }}
                </template>
              </td>
            </tr>
            <tr>
              <th class="font-weight-bold w-50">Date of Contract</th>
              <!-- <td class="w-50">{{ vesselInformation.launched_date }}</td> -->
              <td class="w-50">
                <input type="date" class="form-control form-control-sm" v-if="toEdit"
                       v-model="editVesselDetails.date_contract">
                <span v-else>{{ vessel.date_contract }}</span>
              </td>
            </tr>
            <tr>
              <th class="font-weight-bold w-50">Date of Keel Lay</th>
              <!-- <td class="w-50">{{ vesselInformation.delivery_date }}</td> -->
              <td class="w-50">
                <input type="date" class="form-control form-control-sm" v-if="toEdit"
                       v-model="editVesselDetails.date_keel_lay">
                <span v-else>{{ vessel.date_keel_lay }}</span>
              </td>
            </tr>
            <tr>
              <th class="font-weight-bold w-50">Date of Launch</th>
              <!-- <td class="w-50">{{ vesselInformation.delivery_date }}</td> -->
              <td class="w-50">
                <input type="date" class="form-control form-control-sm" v-if="toEdit"
                       v-model="editVesselDetails.date_launch">
                <span v-else>{{ vessel.date_launch }}</span>
              </td>
            </tr>
            <tr>
              <th class="font-weight-bold w-50">Date of Build</th>
              <!-- <td class="w-50">{{ vesselInformation.delivery_date }}</td> -->
              <td class="w-50">
                <input type="date" class="form-control form-control-sm" v-if="toEdit"
                       v-model="editVesselDetails.date_build">
                <span v-else>{{ vessel.date_build }}</span>
              </td>
            </tr>
            <tr>
              <th class="font-weight-bold w-50">Age (as of Today)</th>
              <!-- <td class="w-50">{{ vesselInformation.delivery_date }}</td> -->
              <td class="w-50">
                <span>{{ toEdit ? editVesselDetails.age : vessel.age }}</span>
                <!-- todo compute age -->
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>


      <div class="row text-small mt-3">
        <div class="col-12">
          <table class="table table-sm w-100">
            <tbody>
            <tr>
              <td class="font-weight-bold" style="width: 10%;">ISM Management Company</td>
              <td>
                <select class="form-control form-control-sm" v-if="toEdit"
                        v-model="editVesselDetails.management_company_id">
                  <option :value="company.id" :key="company.id" class="text-uppercase"
                          v-for="company in mngmtCompanyKeys">
                    {{ company.name ? company.name.toUpperCase() : '' }}
                  </option>
                </select>
                <span v-else>{{ vessel.management_company_name }}</span>
              </td>
            </tr>
            <tr>
              <td class="font-weight-bold w-50">Management Branch Office</td>
              <td class="w-50">
                <select class="form-control form-control-sm text-uppercase" v-if="toEdit"
                        v-model="editVesselDetails.management_office_id">
                  <option :value="office.id" v-for="office in mngmntOfficeKeys" :key="office.id">
                    {{ office.name ? office.name.toUpperCase() : '' }}
                  </option>
                </select>
                <span v-else>{{ vessel.management_office_name }}</span>
              </td>
            </tr>
            <tr>
              <td class="font-weight-bold w-50">Fleet</td>
              <td class="w-50">
                <select class="form-control form-control-sm" v-if="toEdit" v-model="editVesselDetails.fleet_id">
                  <option :value="fleet.id" :key="fleet.id" class="text-uppercase" :disabled="fleet.id == 11" v-for="fleet in fleetKeys">
                    {{ fleet.name ? fleet.name.toUpperCase() : ''}}
                  </option>
                </select>
                <span v-else>{{ vessel.fleet_name }}</span>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="row text-small">
        <div class="col-6">
          <p class="text-bold text-danger mb-0" style="font-size: 15px">Main Engine</p>
          <table class="table table-sm">
            <tbody>
             <tr>
               <td class="font-weight-bold"> Maker</td>
               <td class="w-50">
                 <select class="form-control form-control-sm text-uppercase" v-if="toEdit" :disabled="!engineMaker.length"
                         v-model="editVesselDetails.engine_maker_id">
                   <option :value="null">--- SELECT ENGINE MAKER ---</option>
                   <option :value="engineMaker.id" v-for="engineMaker in engineMaker" :key="engineMaker.id">
                     {{ engineMaker.name ? engineMaker.name.toUpperCase() : '' }}
                   </option>
                 </select>
                 <span v-else>{{ vessel.engine_maker ? vessel.engine_maker.toUpperCase() : '' }} </span>
               </td>
             </tr>
             <tr>
               <td class="font-weight-bold"> Type</td>
               <td class="w-50">
                 <select class="form-control form-control-sm text-uppercase" v-if="toEdit"
                         :disabled="!engineType.length"
                         v-model="editVesselDetails.engine_type_id">
                   <option :value="null">--- SELECT ENGINE TYPE ---</option>
                   <option :value="engineType.id" v-for="engineType in engineType" :key="engineType.id">
                     {{ engineType.name ? engineType.name.toUpperCase() : '' }}
                   </option>
                 </select>
                 <span v-else>{{ vessel.engine_type ? vessel.engine_type.toUpperCase() : '' }} </span>
               </td>
             </tr>
            </tbody>
          </table>
          <p class="text-bold text-danger mb-0" style="font-size: 15px">Turbo Charger</p>
          <table class="table table-sm">
            <tbody>
            <tr>
              <td class="font-weight-bold"> T/C Maker</td>
              <td class="w-50">
                <select class="form-control form-control-sm text-uppercase"
                        v-if="toEdit"
                        :disabled="!engineTcMaker.length"
                        v-model="editVesselDetails.engine_tc_maker_id">
                  <option :value="null">--- SELECT T/C MAKER ---</option>
                  <option :value="engineTcMaker.id" v-for="engineTcMaker in engineTcMaker" :key="engineTcMaker.id">
                    {{ engineTcMaker.name ? engineTcMaker.name.toUpperCase() : '' }}
                  </option>
                </select>
                <span v-else>{{ vessel.engine_tc_maker ? vessel.engine_tc_maker.toUpperCase() : '' }} </span>
              </td>
            </tr>
            <tr>
              <td class="font-weight-bold"> T/C Type</td>
              <td class="w-50">
                <select class="form-control form-control-sm text-uppercase"
                        :disabled="!engineTcType.length"
                        v-if="toEdit"
                        v-model="editVesselDetails.engine_tc_type_id">
                  <option :value="null">--- SELECT T/C TYPE ---</option>
                  <option :value="engineTcType.id" v-for="engineTcType in engineTcType" :key="engineTcType.id">
                    {{ engineTcType.name ? engineTcType.name.toUpperCase() : '' }}
                  </option>
                </select>
                <span v-else>{{ vessel.engine_tc_type ? vessel.engine_tc_type.toUpperCase() : '' }} </span>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="col-6">
          <p class="text-bold text-danger mb-0" style="font-size: 15px">Diesel Generator</p>
          <table class="table table-sm px-3">
            <tbody>
            <tr>
              <td class="font-weight-bold">Maker</td>
              <td class="w-50">
                <select class="form-control form-control-sm text-uppercase" v-if="toEdit"
                        :disabled="!engineDgMaker.length"
                        v-model="editVesselDetails.engine_dg_maker_id">
                  <option :value="null">--- SELECT D/G MAKER ---</option>
                  <option :value="null">--- SELECT D/G MAKER ---</option>
                  <option :value="engineDgMaker.id" v-for="engineDgMaker in engineDgMaker" :key="engineDgMaker.id">
                    {{ engineDgMaker.name ? engineDgMaker.name.toUpperCase() : '' }}
                  </option>
                </select>
                <span v-else>{{ vessel.engine_dg_maker ? vessel.engine_dg_maker.toUpperCase() : '' }} </span>
              </td>
            </tr>
            <tr>
              <td class="font-weight-bold">Type</td>
              <td class="w-50">
                <select class="form-control form-control-sm text-uppercase"
                        :disabled="!engineDgType.length"
                        v-if="toEdit"
                        v-model="editVesselDetails.engine_dg_type_id">
                  <option :value="null">--- SELECT D/G TYPE ---</option>
                  <option :value="engineDgType.id" v-for="engineDgType in engineDgType" :key="engineDgType.id">
                    {{ engineDgType.name ? engineDgType.name.toUpperCase() : '' }}
                  </option>
                </select>
                <span v-else>{{ vessel.engine_dg_type ? vessel.engine_dg_type.toUpperCase() : '' }} </span>
              </td>
            </tr>
            </tbody>
          </table>
          <p class="text-bold text-danger mb-0" style="font-size: 15px">Auxiliary Boiler</p>
          <table class="table table-sm px-3">
            <tbody>
            <tr>
              <td class="font-weight-bold"> Aux Boiler Maker</td>
              <td class="w-50">
                <select class="form-control form-control-sm text-uppercase"
                        v-if="toEdit"
                        :disabled="!engineAuxMaker.length"
                        v-model="editVesselDetails.engine_aux_maker_id">
                  <option :value="null">--- SELECT AUX BOILER MAKER ---</option>
                  <option :value="engineAuxMaker.id" v-for="engineAuxMaker in engineAuxMaker" :key="engineAuxMaker.id">
                    {{ engineAuxMaker.name ? engineAuxMaker.name.toUpperCase() : '' }}
                  </option>
                </select>
                <span v-else>{{ vessel.engine_aux_maker ? vessel.engine_aux_maker.toUpperCase() : '' }} </span>
              </td>
            </tr>
            <tr>
              <td class="font-weight-bold"> Aux Boiler Type</td>
              <td class="w-50">
                <select class="form-control form-control-sm text-uppercase" v-if="toEdit"
                        :disabled="!engineAuxType.length"
                        v-model="editVesselDetails.engine_aux_type_id">
                  <option :value="null">--- SELECT AUX BOILER TYPE ---</option>
                  <option :value="engineAuxType.id" v-for="engineAuxType in engineAuxType" :key="engineAuxType.id">
                    {{ engineAuxType.name ? engineAuxType.name.toUpperCase() : '' }}
                  </option>
                </select>
                <span v-else>{{ vessel.engine_aux_type ? vessel.engine_aux_type.toUpperCase() : '' }} </span>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="row text-small mt-3">
        <div class="col-6">
          <table class="table table-sm">
            <tbody>
            <tr>
              <td class="font-weight-bold">Registered Owner 1</td>
              <td class="w-50">
                <input type="text" class="form-control form-control-sm" v-if="toEdit"
                       v-model="editVesselDetails.registered_owner_one">
                <span v-else>{{ vessel.registered_owner_one }}</span>
              </td>
            </tr>
            <tr>
              <td class="font-weight-bold w-50">Registered Owner 2</td>
              <td class="w-50">
                <input type="text" class="form-control form-control-sm" v-if="toEdit"
                       v-model="editVesselDetails.registered_owner_two">
                <span v-else>{{ vessel.registered_owner_two }}</span>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="col-3">
          <table class="table table-sm">
            <tbody>
            <tr>
              <td class="font-weight-bold">IMO No.</td>
              <td class="w-50">
                <input type="text" v-model="editVesselDetails.imo_no" id="imo_no" class="form-control form-control-sm" v-if="toEdit"
                       @input="preventE"
                >
                <span v-else>{{ vessel.imo_no }}</span>
              </td>
            </tr>
            <tr>
              <td class="font-weight-bold w-50">IMO No.</td>
              <td class="w-50">
                <input type="text" v-model="editVesselDetails.registered_owner_two_imo_no" id="registered_owner_two_imo_no" class="form-control form-control-sm" v-if="toEdit"
                       @input="preventE"

                >
                <span v-else>{{ vessel.registered_owner_two_imo_no }}</span>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="col-3">
          <table class="table table-sm">
            <tbody>
            <tr>
              <th class="font-weight-bold w-50">BBC Owner 1</th>
              <td class="w-50">
                <input type="text" class="form-control form-control-sm" v-if="toEdit"
                       v-model="editVesselDetails.bbc_owner_one">
                <span v-else>{{ vessel.bbc_owner_one }}</span>
              </td>
            </tr>
            <tr>
              <th class="font-weight-bold w-50">BBC Owner 2</th>
              <td class="w-50">
                <input type="text" class="form-control form-control-sm" v-if="toEdit"
                       v-model="editVesselDetails.bbc_owner_two">
                <span v-else>{{ vessel.bbc_owner_two }}</span>
              </td>
            </tr>

            </tbody>
          </table>
        </div>
      </div>

      <div class="row text-small mt-3">
        <div class="col-7">
          <table class="table table-sm">
            <tbody>
            <tr>
              <td class="font-weight-bold">E-mail Address</td>
              <td class="w-50">
                <input type="email" class="form-control form-control-sm" v-if="toEdit"
                       v-model="editVesselDetails.email_address">
                <span v-else>{{ vessel.email_address }}</span>
              </td>
            </tr>
            <tr>
              <td class="font-weight-bold w-50">Tel VSAT W/H direct phone</td>
              <td class="w-50">
                <input type="tel" class="form-control form-control-sm" v-if="toEdit"
                       v-model="editVesselDetails.tel_vsat_direct">
                <span v-else>{{ vessel.tel_vsat_direct }}</span>
              </td>
            </tr>
            <tr>
              <td class="font-weight-bold w-50">Tel Inmarsat FBB phone</td>
              <td class="w-50">
                <input type="tel" class="form-control form-control-sm" v-if="toEdit"
                       v-model="editVesselDetails.tel_inmarsat_fbb">
                <span v-else>{{ vessel.tel_inmarsat_fbb }}</span>
              </td>
            </tr>
            <tr>
              <td class="font-weight-bold w-50">Tel VSAT Ship's office direct phone</td>
              <td class="w-50">
                <input type="tel" class="form-control form-control-sm" v-if="toEdit"
                       v-model="editVesselDetails.tel_vsat_ship_office">
                <span v-else>{{ vessel.tel_vsat_ship_office }}</span>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="col-5">
          <table class="table table-sm">
            <tbody>
            <tr>
              <th class="font-weight-bold w-50"> Fax Inmarsat FBB</th>
              <td class="w-50">
                <input type="text" class="form-control form-control-sm" v-if="toEdit"
                       v-model="editVesselDetails.fax_inmarsat_fbb">
                <span v-else>{{ vessel.fax_inmarsat_fbb }}</span>
              </td>
            </tr>
            <tr>
              <th class="font-weight-bold w-50">Inmarsat C</th>
              <td class="w-50">
                <input type="tel" class="form-control form-control-sm" v-if="toEdit"
                       v-model="editVesselDetails.inmarsat_c">
                <span v-else>{{ vessel.inmarsat_c }}</span>
              </td>
            </tr>
            <tr>
              <th class="font-weight-bold w-50">Mobile (In port only)</th>
              <td class="w-50">
                <input type="text" class="form-control form-control-sm" v-if="toEdit"
                       v-model="editVesselDetails.mobile">
                <span v-else>{{ vessel.mobile }}</span>
              </td>
            </tr>

            </tbody>
          </table>
        </div>
      </div>


    </form>

    <PopperModal popper-id="sisterShipList">
      <template #popperHeader>{{ vessel.name }} SISTER SHIP(S)</template>
      <template #popperBody>
        <table class="table-bordered table table-sm text-center">
          <tr>
            <th>#</th>
            <th>NAME</th>
            <th>DATE BUILD</th>
            <th>AGE</th>
            <th>SHIPBUILDER</th>
          </tr>
          <tr v-for="(sisVessel, index) in sisterVessels" :key="sisVessel.id">
            <th>{{ index + 1 }}</th>
            <td>{{sisVessel.name}}
              <span class="font-weight-bold e-text-red" v-if="sisVessel.is_eldest">( ELDEST SHIP )</span>
            </td>
            <td>{{sisVessel.date_build}}</td>
            <td>{{sisVessel.age}}</td>
            <td>{{sisVessel.shipbuilder}}</td>
          </tr>
        </table>
      </template>
    </PopperModal>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import {AlertService} from '@/services/AlertService'
import {VesselService} from '@/services/VesselService'
import {DataService} from '@/services/DataService'
import {vueAppMixin} from '@/mixins/vueAppMixin'
import Swal from "sweetalert2";
import VesselModule from "@/store/modules/VesselModule";
import {ReportService} from "@/services/ReportService";
import {permissionMixin} from "@/mixins/permissionMixins";
import PopperModal from "@/components/elements/PopperModal.vue";
import {PSC_REPORT_INDEX, VESSEL_DETAIL} from "@/services/ConstantService";
import {UrlService} from "@/services/UrlService";
import axios from "axios";
import {GlobalService} from "@/services/GlobalService";

export default {
  name: 'VesselShow',
  components: {PopperModal},
  mixins: [vueAppMixin,permissionMixin],

  data() {
    return {
      editVesselDetails: {
        mannings: [],
      },
      vesselInformation: {},
      vesselInformationErrors: {},

      toEdit: false,

    }
  },
  methods: {
    ...mapActions([
      '' +
      'getMngmtOfficeKeys',
      'getVesselDetail',
      'getManagementCompanyKeys',
      'getFleetListKeys',
      'getCountryListKeys',
      'getVesselTypeKeys',
      'getManningKeys',
      'updateVessel',
      'getEngineMakers',
      'getEngineTypes',
      'getEngineDgTypes',
      'getEngineDgMakers',
      'getEngineTcTypes',
      'getEngineTcMakers',
      'getEngineAuxMakers',
      'getEngineAuxTypes',
      'getSisterVessels'
    ]),
    preventE(event) {
      const inputKey = $(event.target).attr('id');
      this.editVesselDetails[inputKey] = event.target.value.replace(/[^0-9]/g, "");
    },
    async cancelEditVessel(){
      if(await AlertService.cancelAlert()){
        this.editVesselDetails = Object.assign({}, this.vessel);
        this.toEdit = false
      }
    },

    async downloadAIReportTemplate() {
      const vessel = this.editVesselDetails;
      swal.fire({title: 'Please wait'});
      swal.showLoading();

      let filename = 'Accident_Incident_Report' + '_' + (vessel.name).replace(' ', '_').toUpperCase() + '_TEMPLATE.xlsx';
      let mime_type = 'application/vnd.ms-excel';
      let file = await ReportService.downloadAIReportTemplate(vessel.id)
      let url = window.URL.createObjectURL(new Blob([file.data],{type:mime_type}));
      const link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('download', filename);

      swal.close();
      document.body.appendChild(link);
      link.click();
    },

    async downloadParticular(fileType) {
      const vessel = this.editVesselDetails;
      let params = {};
      params[fileType] = 1;

      swal.fire({
        'title': `DOWNLOADING VESSEL PARTICULARS <br>Please wait.....`,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false
      })
      swal.showLoading()
      const downloadResponse = await ReportService.downloadVesselParticular(vessel.id, params);
        swal.close()
        let mime_type = fileType==='pdf' ? 'application/pdf' : 'application/vnd.ms-excel'
        let url = window.URL.createObjectURL(new Blob([downloadResponse.data],{type:mime_type}))

        fileType = (fileType !== 'pdf') ? 'xlsx' : 'pdf';
        let filename=`VESSEL_PARTICULARS_${vessel.name.toUpperCase()}_${Date.now()}.${fileType}`
        const link = document.createElement('a');
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
    },

    async confirmUpdateVessel() {
      let self = this;
      let formData = new FormData();
      if (await AlertService.confirmUpdateAlert()) {

        let vesselImageInput = document.getElementById("vessel_image");

        if (vesselImageInput.files && vesselImageInput.files[0]) {
          formData.append("vessel_image", vesselImageInput.files[0]);
        }

        Object.keys(this.editVesselDetails).forEach((key) => {
          if (key === "vessel_image") return;
          if (key === "mannings") {
            let mannings = this.editVesselDetails["mannings"];
            for (let i = 0; i < mannings.length; i++) {
              formData.append("mannings[]", mannings[i].id);
            }
          } else {
            if (!this.editVesselDetails[key]) {
              formData.set(key, "");
              return;
            }
            formData.set(key, this.editVesselDetails[key]);
          }
        });


        // const selectedMannings = [];
        // // Object.assign(this.mannings).forEach((val, index) => {
        // //   selectedMannings.push(val.crew_change_id);
        // // })
        // for (let i = 0; i < this.editVesselDetails.mannings.length; i++) {
        //   selectedMannings.push(this.editVesselDetails.mannings[i].id);
        // }
        // console.log(selectedMannings);
        //
        //
        // let params = {
        //   vesselDetails: this.editVesselDetails,
        //   mannings: selectedMannings,
        // }

        await this.updateVessel(formData);
        AlertService.successAlert('Done Updating Report Details', 'UPDATE');
        this.toEdit = false;
        await this.getVesselDetail(this.editVesselDetails.id);
        this.editVesselDetails = Object.assign({}, this.vessel);

      }
    },
    editValues() {
      this.toEdit = true
      if (this.toEdit) {
        // this.editVesselDetails = Object.assign({}, this.vessel);
      }
    },
    getVesselAge(dateOfBuild) {
      const age = DataService.computeAge(dateOfBuild)
      return age || ''
    },

    async initializeData() {
      let pleaseWait = Swal.fire({
        title: 'Loading...',
        html: 'Please wait...',
        allowEscapeKey: false,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading()
        }
      });

      await this.getMngmtOfficeKeys();
      await this.getManagementCompanyKeys();
      await this.getFleetListKeys();
      await this.getCountryListKeys();
      await this.getVesselTypeKeys();
      await this.getManningKeys();

      await this.getEngineMakers({get:true})
      await this.getEngineTypes({get:true})
      await this.getEngineTcMakers({get:true})
      await this.getEngineTcTypes({get:true})
      await this.getEngineDgMakers({get:true})
      await this.getEngineDgTypes({get:true})
      await this.getEngineAuxTypes({get:true})
      await this.getEngineAuxMakers({get:true})

      await this.getVesselDetail(this.$route.params.id);
      this.editVesselDetails = Object.assign({}, this.vessel);
      // await this.getVesselInfo({vesselId: this.$route.params.id});
      // this.vesselInformation = Object.assign({}, this.vesselInfo);
      await this.getSisterVessels({'vessel_id': parseInt(this.$route.params.id)});
      pleaseWait.close();

    },

    isManningChecked(manningKeyId) {
      if (!this.vessel.mannings) return false;

      return this.vessel.mannings.find(
        (manning) => manning.id == manningKeyId
      );
    },

    onChange(e) {
      // console.log(e.target.value);
      // console.log(e);
    },


  },
  created() {
    this.initializeData();
  },

  computed: {
    ...mapGetters([
      'mngmntOfficeKeys',
      'vessel',
      'fleetKeys',
      'mngmtCompanyKeys',
      'countryKeys',
      'vesselTypeKeys',
      'manningKeys',
      'engineDgMaker',
      'engineDgType',
      'engineType',
      'engineMaker',
      'engineTcMaker',
      'engineTcType',
      'engineAuxMaker',
      'engineAuxType',
      'sisterVessels'
    ])
  },

  watch: {
    'this.editVesselDetails.mannings'() {
      // console.log(editVesselDetails.mannings, 'editVesselDetails.mannings')
    }
  }

}
</script>

<style scoped lang="scss">
#vesselDetailsTbl {
  th {
    width: 13rem;
  }

  td {
    padding: .25rem;
    text-align: left;
  }
}

#veselOwnerTbl {
  th {
    width: 13rem;
  }

  td {
    padding: .25rem;
    text-align: left;
  }
}

.image-vessel {
  width: 250px;
  height: auto;
}

.sm-button-font {
  font-size: 12px;
}
</style>
